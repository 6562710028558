const DEFAULT_LOCALE = "en_US";

const translations = {
  en_US: require("../locales/en_US.json"),
  de_DE: require("../locales/de_DE.json"),
  ru_RU: require("../locales/ru_RU.json"),
  fr_FR: require("../locales/fr_FR.json"),
  en_UK: require("../locales/en_UK.json"),
  en_RU: require("../locales/en_RU.json")
};

// default to window object emnosPortalAssets if none passed in
export function getTranslations(emnosPortalAssets = window.emnosPortalAssets) {
  // merge with window object translations
  if (emnosPortalAssets && typeof emnosPortalAssets.translations === "object") {
    for (const locale in emnosPortalAssets.translations) {
      translations[locale] = {
        ...translations[locale],
        ...emnosPortalAssets.translations[locale]
      };
    }
  }
  return translations;
}

export function getTranslationsByLocale(
  emnosPortalAssets,
  locale = DEFAULT_LOCALE
) {
  const locales = getTranslations(emnosPortalAssets);
  const hasLocale = locales.hasOwnProperty(locale);
  return hasLocale ? locales[locale] : locales[DEFAULT_LOCALE];
}
