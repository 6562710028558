import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import classNames from "classnames";

const styles = theme => ({
  root: {
    margin: 4,
    flexGrow: 1,
    minWidth: 160,
    backgroundColor: "transparent",
    color: theme.status.clay[100],
    borderColor: theme.status.clay[100],
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 20,
    transitionProperty: "border-color, color",
    transitionDuration: "150ms, 150ms",
    transitionTimingFunction: "linear, linear",
    "&:hover": {
      backgroundColor: theme.status.clay[100],
      borderColor: theme.status.clay[100],
      color: theme.status.midnight[100]
    }
  },
  selected: {
    backgroundColor: theme.status.midnight[90],
    borderColor: theme.status.midnight[90],
    color: theme.status.grey.white,
    "&:hover": {
      backgroundColor: theme.status.midnight[100],
      borderColor: theme.status.midnight[100],
      color: theme.status.grey.white
    }
  },
  text: {
    padding: "6px 12px"
  }
});

const ToggleButton = React.forwardRef(function ToggleButton(props, ref) {
  const { className, classes, selected, children, ...remainingProps } = props;
  return (
    <ButtonBase
      className={classNames(
        classes.root,
        {
          [classes.selected]: selected
        },
        className
      )}
      ref={ref}
      aria-pressed={selected}
      disableRipple
      {...remainingProps}
    >
      <span className={classes.text}>{children}</span>
    </ButtonBase>
  );
});

ToggleButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
  selected: PropTypes.bool.isRequired
};

export default withStyles(styles)(ToggleButton);
