import React from "react";
import PropTypes from "prop-types";

import styles from "./SearchInput.module.css";

export default function SearchInput({ searchValue, handleChange }) {
  return (
    <div className={styles.searchInput}>
      <input
        className={styles.input}
        autoFocus
        placeholder="Enter search term"
        type="text"
        value={searchValue}
        onChange={handleChange}
      />
    </div>
  );
}

SearchInput.propTypes = {
  searchValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};
