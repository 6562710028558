import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import ListPagination from "./ListPagination";
import ListItem from "./ListItem";

import styles from "./List.module.css";

export default function List({
  depth = 0,
  data,
  nodes,
  collapseNode,
  expandNode,
  selectNode,
  deselectNode,
  role,
  perPage,
  parentSelected
}) {
  const enhancedNodes = useMemo(() => nodes.map(id => data[id]));

  const sortByKey = (a, b) => {
    const hasSortKey =
      typeof a.hierarchySortKey !== "undefined" && a.hierarchySortKey !== null;
    const sortValueKey = hasSortKey ? "hierarchySortKey" : "hierarchyName";
    const sortValueA = a[sortValueKey];
    const sortValueB = b[sortValueKey];
    return sortValueA.localeCompare(sortValueB);
  };

  const sortByType = (a, b) => {
    let sortScoreA = !a.userHierarchyNode && !a.dynamicHierarchyNode ? 1 : 0;
    let sortScoreB = !b.userHierarchyNode && !b.dynamicHierarchyNode ? 1 : 0;
    return sortScoreB - sortScoreA;
  };

  const sortedNodes = useMemo(
    () => enhancedNodes.sort(depth === 0 ? sortByType : sortByKey),

    [enhancedNodes, depth]
  );

  const searchFilteredNodes = useMemo(() => {
    // TODO: remove, we already filtered by search in listitem
    return sortedNodes;
  }, [sortedNodes]);

  const totalPages = useMemo(() => {
    return Math.ceil(searchFilteredNodes.length / perPage);
  }, [searchFilteredNodes, perPage]);

  const [page, setPage] = useState(0);

  const handlePrev = useCallback(() => {
    if (page > 0) {
      setPage(page - 1);
    }
  }, [page]);

  const handleNext = useCallback(() => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  }, [page]);

  const handleSelectPage = useCallback(newPage => {
    setPage(newPage);
  }, []);

  const paginatedNodes = useMemo(() => {
    return searchFilteredNodes.slice(page * perPage, page * perPage + perPage);
  }, [searchFilteredNodes, perPage, page]);

  return (
    <ul className={styles.list} role={role}>
      <ListPagination
        handlePrev={handlePrev}
        handleNext={handleNext}
        handleSelectPage={handleSelectPage}
        page={page}
        totalPages={totalPages}
      />
      {paginatedNodes.map(el => (
        <ListItem
          data={data}
          nodes={el.childrenIds}
          perPage={perPage}
          link={!!el.link}
          leaf={el.leaf}
          expanded={!!el.expanded}
          selected={!!el.selected}
          userHierarchyNode={!!el.userHierarchyNode}
          dynamicHierarchyNode={!!el.dynamicHierarchyNode}
          handleClickToggle={event => {
            return el.expanded
              ? collapseNode(el.id, event)
              : expandNode(el.id, event);
          }}
          handleClickLabel={event => {
            if (!el.selectable) {
              console.warn("Node is unselectable");
              return;
            }
            if (parentSelected) {
              return;
            }
            return el.selected
              ? deselectNode(el.id, event)
              : selectNode(el.id, event);
          }}
          key={el.id}
          memberKey={el.memberKey}
          hierarchyName={el.hierarchyName}
          hierarchyNamePrefix={el.hierarchyNamePrefix}
        >
          {({ childrenNodeIds }) => (
            <List
              depth={depth + 1}
              data={data}
              nodes={childrenNodeIds}
              perPage={perPage}
              parentSelected={el.selected}
              collapseNode={collapseNode}
              expandNode={expandNode}
              selectNode={selectNode}
              deselectNode={deselectNode}
              role="group"
            />
          )}
        </ListItem>
      ))}
    </ul>
  );
}

List.defaultProps = {
  perPage: 20,
  role: "tree",
  parentShowSearchInput: false,
  // parent being selected prevents child selection
  parentSelected: false
};

List.propTypes = {
  depth: PropTypes.number,
  perPage: PropTypes.number,
  role: PropTypes.string,
  data: PropTypes.object.isRequired,
  nodes: PropTypes.array.isRequired,
  collapseNode: PropTypes.func.isRequired,
  expandNode: PropTypes.func.isRequired,
  selectNode: PropTypes.func.isRequired,
  deselectNode: PropTypes.func.isRequired,
  parentShowSearchInput: PropTypes.bool,
  parentSelected: PropTypes.bool
};
