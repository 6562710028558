// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2XY0a34HWi-ZqjtpFrgP8t {\n  background-color: transparent;\n  border: none;\n  appearance: none;\n}\n\n._2XY0a34HWi-ZqjtpFrgP8t:hover {\n  opacity: 0.75;\n}\n\n._28hbo3moKQ3WaGsLYtyHA7 {\n  position: relative;\n  display: flex;\n}\n\n._3j4pro3sNqsQn9cKlu5LUn {\n  border: none;\n  appearance: none;\n  margin: 2px;\n  width: 24px;\n  height: 24px;\n  text-align: center;\n  color: gray;\n  border-radius: 2px;\n  background-color: whitesmoke;\n}\n\n.ucvR3ItohtS5B9DKu1RKK {\n  color: black;\n  background-color: lightgray;\n}\n\n._3j4pro3sNqsQn9cKlu5LUn:focus {\n  outline: none;\n}\n\n._3j4pro3sNqsQn9cKlu5LUn:disabled {\n  opacity: 0.5;\n}\n._3j4pro3sNqsQn9cKlu5LUn:disabled ._3j4pro3sNqsQn9cKlu5LUn:hover {\n  opacity: 0.5;\n}\n._3j4pro3sNqsQn9cKlu5LUn:hover {\n  opacity: 0.75;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "_2XY0a34HWi-ZqjtpFrgP8t",
	"pagination": "_28hbo3moKQ3WaGsLYtyHA7",
	"pagination-button": "_3j4pro3sNqsQn9cKlu5LUn",
	"paginationButton": "_3j4pro3sNqsQn9cKlu5LUn",
	"pagination-button-active": "ucvR3ItohtS5B9DKu1RKK",
	"paginationButtonActive": "ucvR3ItohtS5B9DKu1RKK"
};
module.exports = exports;
