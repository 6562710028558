import React, { useReducer, useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import TimePeriodToggleGroup from "./TimePeriodToggleGroup";
import TimePeriodDurationSlider from "./TimePeriodDurationSlider";
import TimePeriodDatePickerContainer from "./TimePeriodDatePickerContainer";
import TimePeriodWarning from "./TimePeriodWarning";

const StyledPaper = withStyles({
  root: {
    padding: 20,
    minWidth: 350,
    display: "flex",
    flexDirection: "row"
  }
})(Paper);

const StyledPaperGrow = withStyles({
  root: {
    marginLeft: 10,
    padding: 30,
    flexGrow: 1
  }
})(Paper);

const StyledPaperCentre = withStyles({
  root: {
    padding: 10,
    display: "flex",
    alignItems: "center"
  }
})(Paper);

// Time period constants
export const TIME_PERIOD = {
  ADHOC: "ADHOC",
  ROLLING_YEAR: "ROLLING_YEAR",
  YEAR_TO_DATE: "YEAR_TO_DATE",
  LAST_X_WEEKS: "LAST_X_WEEKS"
};

// Date constants
const DEFAULT_FORMAT = "YYYY-MM-DD";

// Action types
const CURRENT_DATE = "CURRENT_DATE";

// Actions
export function setPeriodAdhoc(
  initStartDate,
  initEndDate,
  minDate,
  maxDate,
  defaultMonthsFromEndDate
) {
  const hasInitDates = Boolean(initStartDate && initEndDate);

  return {
    type: CURRENT_DATE,
    payload: hasInitDates
      ? getStartDateFromInitialDates(
          initStartDate,
          initEndDate,
          minDate,
          maxDate
        )
      : getStartDateFromMaxDate(minDate, maxDate, defaultMonthsFromEndDate)
  };
}

export function setPeriodRollingYear(maxDate) {
  // from end date of transactions availability to one year behind
  const newStartDate = maxDate.clone().subtract(12, "months");
  const diffDays = maxDate.diff(newStartDate, "days");
  const currSliderValue = maxDate.diff(newStartDate, "weeks");
  return {
    type: CURRENT_DATE,
    payload: {
      currentDate: newStartDate.format(DEFAULT_FORMAT),
      diffDays: diffDays,
      sliderValue: currSliderValue,
      maxSliderValue: currSliderValue
    }
  };
}

export function setPeriodYearToDate(maxDate) {
  const newStartDate = maxDate.clone().startOf("year");
  const diffDays = maxDate.diff(newStartDate, "days");
  const currSliderValue = maxDate.diff(newStartDate, "weeks");
  return {
    type: CURRENT_DATE,
    payload: {
      currentDate: newStartDate.format(DEFAULT_FORMAT),
      diffDays: diffDays,
      sliderValue: currSliderValue,
      maxSliderValue: currSliderValue
    }
  };
}

export function setPeriodLastXWeeks(minDate, maxDate) {
  const newStartDate = maxDate.clone().subtract(52, "weeks");
  const diffDays = maxDate.diff(newStartDate, "days");
  const currSliderValue = maxDate.diff(newStartDate, "weeks");
  const maxSliderValue = maxDate.diff(minDate, "weeks");
  // handle diff
  return {
    type: CURRENT_DATE,
    payload: {
      currentDate: newStartDate.format(DEFAULT_FORMAT),
      diffDays: diffDays,
      sliderValue: currSliderValue,
      maxSliderValue: maxSliderValue
    }
  };
}

export function setCurrentDate(newDate, minDate, maxDate) {
  const maxSliderValue = maxDate.diff(newDate, "weeks");
  return {
    type: CURRENT_DATE,
    payload: {
      currentDate: newDate.format(DEFAULT_FORMAT),
      maxSliderValue: maxSliderValue
    }
  };
}

export function setEndDate(newDate, currentDate) {
  const mCurrentDate = moment(currentDate);
  const newDiffDays = newDate.diff(mCurrentDate, "days");
  const newSliderValue = newDate.diff(mCurrentDate, "weeks");
  return {
    type: CURRENT_DATE,
    payload: {
      diffDays: newDiffDays,
      sliderValue: newSliderValue
    }
  };
}

export function updateSliderValue(
  currentDate,
  newValue,
  minDate,
  maxDate,
  reverse
) {
  let newCurrentDate;
  let newDiffDays;

  if (reverse) {
    // count slider weeks from max date
    // current date will change
    newCurrentDate = maxDate.clone().subtract(newValue, "weeks");
    newDiffDays = maxDate.diff(newCurrentDate, "days");
    newCurrentDate = newCurrentDate.format(DEFAULT_FORMAT);
  } else {
    newCurrentDate = moment(currentDate);
    const newEndDate = newCurrentDate.clone().add(newValue, "weeks");
    newDiffDays = newEndDate.diff(newCurrentDate, "days");
    newCurrentDate = currentDate;
  }

  return {
    type: CURRENT_DATE,
    payload: {
      currentDate: newCurrentDate,
      diffDays: newDiffDays,
      sliderValue: newValue
    }
  };
}

// Initial state
export const initialState = {
  currentDate: null, // start date if forward
  diffDays: 0, // calculate distance from currentDate
  sliderValue: 0,
  maxSliderValue: 0
};

// Determine initial state from props
export function getStartDateFromInitialDates(
  initStartDate,
  initEndDate,
  minDate,
  maxDate
) {
  const mInitStartDate = moment(initStartDate);
  const mInitEndDate = moment(initEndDate);
  const diffDays = mInitEndDate.diff(mInitStartDate, "days");
  // Set duration in weeks
  // Max duration is diff of min/max
  // Current value is diff end(start, weeks)
  const maxSliderValue = maxDate.diff(mInitStartDate, "weeks");
  const currSliderValue = mInitEndDate.diff(mInitStartDate, "weeks");
  return {
    currentDate: mInitStartDate.format(DEFAULT_FORMAT),
    diffDays: diffDays,
    sliderValue: currSliderValue,
    maxSliderValue: maxSliderValue
  };
}

export function getStartDateFromMaxDate(
  minDate,
  maxDate,
  defaultMonthsFromEndDate
) {
  const newStartDate = maxDate
    .clone()
    .subtract(defaultMonthsFromEndDate, "months");
  const clampedStartDate = moment.max(minDate.clone(), newStartDate);
  const diffDays = maxDate.diff(clampedStartDate, "days");
  const maxSliderValue = maxDate.diff(clampedStartDate, "weeks");
  const currSliderValue = maxSliderValue;
  return {
    currentDate: clampedStartDate.format(DEFAULT_FORMAT),
    diffDays: diffDays,
    sliderValue: currSliderValue,
    maxSliderValue: maxSliderValue
  };
}

export function getInitialState(
  initialState,
  { initStartDate, initEndDate, minDate, maxDate, defaultMonthsFromEndDate }
) {
  const hasInitDates = Boolean(initStartDate && initEndDate);
  if (hasInitDates) {
    return {
      ...initialState,
      ...getStartDateFromInitialDates(
        initStartDate,
        initEndDate,
        minDate,
        maxDate
      )
    };
  } else {
    return {
      ...initialState,
      ...getStartDateFromMaxDate(minDate, maxDate, defaultMonthsFromEndDate)
    };
  }
}

export function reducer(state, action) {
  switch (action.type) {
    case CURRENT_DATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      throw new Error();
  }
}

const TimePeriod = ({
  defaultMonthsFromEndDate,
  warningMessage,
  durationLabel,
  initStartDate,
  initEndDate,
  minDate,
  maxDate,
  periods,
  isSaving,
  saveLabel,
  cancelLabel,
  onCancelTimePeriod,
  onSaveTimePeriod
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(initialState, {
      initStartDate,
      initEndDate,
      minDate,
      maxDate,
      defaultMonthsFromEndDate
    })
  );

  // Current period state
  const [period, setPeriod] = useState(periods[0]);

  // change period callbacks
  const onPeriodAdhoc = useCallback(() => {
    dispatch(
      setPeriodAdhoc(
        initStartDate,
        initEndDate,
        minDate,
        maxDate,
        defaultMonthsFromEndDate
      )
    );
  }, [
    dispatch,
    initStartDate,
    initEndDate,
    minDate,
    maxDate,
    defaultMonthsFromEndDate
  ]);

  const onPeriodRollingYear = useCallback(() => {
    dispatch(setPeriodRollingYear(maxDate));
  }, [dispatch, maxDate]);

  const onPeriodYearToDate = useCallback(() => {
    dispatch(setPeriodYearToDate(maxDate));
  }, [dispatch, maxDate]);

  const onPeriodLastXWeeks = useCallback(() => {
    dispatch(setPeriodLastXWeeks(minDate, maxDate));
  }, [dispatch, minDate, maxDate]);

  // for info display
  const availableMonths = useMemo(() => {
    return maxDate.diff(minDate, "months");
  }, [minDate, maxDate]);

  // change start date callback
  const handleChangeStartDate = useCallback(
    newDate => dispatch(setCurrentDate(newDate, minDate, maxDate)),
    [dispatch, minDate, maxDate]
  );

  const handleChangeEndDate = useCallback(
    newDate => dispatch(setEndDate(newDate, state.currentDate)),
    [dispatch, state.currentDate]
  );

  // End date derived from start date plus diff days
  const derivedEndDate = useMemo(() => {
    const currentDatePlusDays = moment(state.currentDate).add(
      state.diffDays,
      "days"
    );
    const clampedDate = moment.min(maxDate, currentDatePlusDays);
    return clampedDate.format(DEFAULT_FORMAT);
  }, [state.currentDate, state.diffDays]);

  // Slider update callback
  const handleChangeSlider = useCallback(
    newValue => {
      dispatch(
        updateSliderValue(
          state.currentDate,
          newValue,
          minDate,
          maxDate,
          period.reverse
        )
      );
    },
    [dispatch, state.currentDate, minDate, maxDate, period.reverse]
  );

  const handleChangePeriod = useCallback(
    newPeriod => {
      // depending on period, update weeks and reset start/end
      setPeriod(newPeriod);
      switch (newPeriod.id) {
        case TIME_PERIOD.ADHOC:
          onPeriodAdhoc();
          break;
        case TIME_PERIOD.ROLLING_YEAR:
          onPeriodRollingYear();
          break;
        case TIME_PERIOD.YEAR_TO_DATE:
          onPeriodYearToDate();
          break;
        case TIME_PERIOD.LAST_X_WEEKS:
          onPeriodLastXWeeks();
          break;
        default:
          break;
      }
    },
    [onPeriodAdhoc, onPeriodRollingYear, onPeriodYearToDate, onPeriodLastXWeeks]
  );

  return (
    <>
      <StyledPaper elevation={0}>
        <StyledPaperCentre elevation={1}>
          <TimePeriodToggleGroup
            handleChange={handleChangePeriod}
            period={period}
            periods={periods}
          />
        </StyledPaperCentre>
        <StyledPaperGrow elevation={1}>
          <TimePeriodDatePickerContainer
            startDate={state.currentDate}
            endDate={derivedEndDate}
            minDate={minDate}
            maxDate={maxDate}
            disableStartDate={period.disableStartDate}
            disableEndDate={period.disableEndDate}
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
          />
          <Typography variant="subtitle1">{durationLabel}</Typography>
          <TimePeriodDurationSlider
            minValue={0}
            maxValue={state.maxSliderValue}
            disabled={period.disableSlider}
            value={state.sliderValue}
            handleChange={handleChangeSlider}
          />
          <Typography style={{ paddingTop: 10 }} variant="caption">
            Available data: {availableMonths}{" "}
            {availableMonths > 1 ? "months" : "month"} up to{" "}
            {maxDate.format("YYYY-MM-DD")}
          </Typography>
        </StyledPaperGrow>
      </StyledPaper>
      <div
        style={{
          paddingBottom: 20,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        {warningMessage && <TimePeriodWarning message={warningMessage} />}
        <Button
          style={{ marginRight: 10 }}
          disabled={isSaving}
          variant="outlined"
          color="primary"
          onClick={onCancelTimePeriod}
        >
          {cancelLabel}
        </Button>
        <Button
          style={{ marginRight: 20 }}
          disabled={isSaving}
          variant="contained"
          color="primary"
          onClick={() =>
            onSaveTimePeriod({
              startDate: state.currentDate,
              endDate: derivedEndDate
            })
          }
        >
          {isSaving && <CircularProgress size={18} />}
          {!isSaving && saveLabel}
        </Button>
      </div>
    </>
  );
};

TimePeriod.defaultProps = {
  defaultMonthsFromEndDate: 12,
  saveLabel: "Save",
  cancelLabel: "Cancel",
  editButtonLabel: "Set time period",
  durationLabel: "Duration (in weeks)",
  periods: [
    {
      id: TIME_PERIOD.ADHOC,
      label: "Adhoc",
      disableStartDate: false,
      disableEndDate: false,
      disableSlider: false
    },
    {
      id: TIME_PERIOD.ROLLING_YEAR,
      label: "Rolling year",
      disableStartDate: true,
      disableEndDate: true,
      disableSlider: true
    },
    {
      id: TIME_PERIOD.YEAR_TO_DATE,
      label: "Year to date",
      disableStartDate: true,
      disableEndDate: true,
      disableSlider: true
    },
    {
      id: TIME_PERIOD.LAST_X_WEEKS,
      label: "Last X weeks",
      disableStartDate: true,
      disableEndDate: true,
      disableSlider: false,
      reverse: true
    }
  ]
};

TimePeriod.propTypes = {
  defaultMonthsFromEndDate: PropTypes.number,
  warningMessage: PropTypes.string,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  initStartDate: PropTypes.string,
  initEndDate: PropTypes.string,
  editButtonLabel: PropTypes.string,
  durationLabel: PropTypes.string,
  periods: PropTypes.array,
  onSaveTimePeriod: PropTypes.func,
  onCancelTimePeriod: PropTypes.func,
  saveLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  isSaving: PropTypes.bool
};

export default TimePeriod;
