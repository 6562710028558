import React from "react";
import PropTypes from "prop-types";

import TimePeriodDatePicker from "./TimePeriodDatePicker";
import TimePeriodDateDisplay from "./TimePeriodDateDisplay";
import { Typography } from "@material-ui/core";

const TimePeriodDatePickerContainer = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  hasPrior,
  disableStartDate,
  disableEndDate,
  handleChangeStartDate,
  handleChangeEndDate
}) => {
  return (
    <div style={{ display: "flex", marginBottom: 30 }}>
      <div>
        <Typography variant="subtitle1">Current period</Typography>
        {disableStartDate && (
          <TimePeriodDateDisplay
            minDate={minDate}
            maxDate={maxDate}
            label="Start"
            dateValue={startDate}
          />
        )}
        {!disableStartDate && (
          <TimePeriodDatePicker
            label="Start"
            minDate={minDate}
            maxDate={maxDate}
            dateValue={startDate}
            handleChange={handleChangeStartDate}
          />
        )}
        {disableEndDate && (
          <TimePeriodDateDisplay
            minDate={minDate}
            maxDate={maxDate}
            label="End"
            dateValue={endDate}
          />
        )}
        {!disableEndDate && (
          <TimePeriodDatePicker
            label="End"
            minDate={startDate}
            maxDate={maxDate}
            dateValue={endDate}
            handleChange={handleChangeEndDate}
          />
        )}
      </div>
      {hasPrior && (
        <div>
          <Typography variant="subtitle1">Prior period</Typography>
          <TimePeriodDatePicker
            label="Start"
            minDate={minDate}
            maxDate={maxDate}
            dateValue={startDate}
            handleChange={handleChangeStartDate}
          />
        </div>
      )}
    </div>
  );
};

TimePeriodDatePickerContainer.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  handleChangeStartDate: PropTypes.func.isRequired,
  handleChangeEndDate: PropTypes.func.isRequired,
  disableStartDate: PropTypes.bool,
  disableEndDate: PropTypes.bool,
  hasPrior: PropTypes.bool
};

TimePeriodDatePickerContainer.defaultProps = {
  hasPrior: false,
  disableStartDate: false,
  disableEndDate: false
};

export default TimePeriodDatePickerContainer;
