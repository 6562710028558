// React
import React, { useContext } from "react";
import PropTypes from "prop-types";
// Material UI
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AdjustIcon from "@material-ui/icons/Adjust";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";

// Components
import SideMenuHeader from "./SideMenuHeader/SideMenuHeader";
import TranslationContext from "../../context/TranslationContext";

// Styles
const styles = theme => ({
  sidemenu: {
    padding: "0 2.2rem",
    outline: "none",
    overflow: "hidden",
    height: "100%"
  },
  extraList: {
    borderTop: `1px solid ${theme.status.grey.greyDivs}`
  },
  item: {
    borderRadius: "3.125rem",
    "&:hover": {
      background: "none"
    },
    "&:hover div, &:hover span": {
      color: theme.status.blue.superDark,
      marginLeft: "0.5px",
      fontWeight: 700
    }
  },
  selectedItem: {
    "&#pl-menu-item div, &#pl-menu-item span, &#pl-menu-extra-item div, &#pl-menu-extra-item span": {
      color: theme.status.blue.superDark,
      marginLeft: "0.5px",
      fontWeight: 700
    }
  },
  textWrapper: {
    padding: "0 8px"
  },
  text: {
    fontSize: "0.875rem",
    fontWeight: 600,
    textTransform: "capitalize"
  },
  icon: {
    width: "1.8rem",
    height: "1.8rem",
    minWidth: 0,
    marginRight: "5px"
  },
  extraIcon: {
    boxSizing: "content-box",
    width: "1.8rem",
    height: "1.8rem",
    minWidth: 0,
    marginRight: "5px",
    color: theme.status.grey.greyDivs
  },
  paper: {
    overflow: "hidden",
    width: "18rem",
  }
});

function sort_by_key(array, key)
{
  return array.sort(function(a, b)
  {
    var x = a[key]; var y = b[key];
    var result;
    if (key === 'rank') {
      result = (x - y);
    } else {
      result = ((x < y) ? -1 : ((x > y) ? 1 : 0));
    }
    return result;
  });
}

const SideMenu = ({
  navigation,
  navigationSortKey,
  currentNavigation,
  appObj,
  handleToggle,
  isOpen,
  analyzerAdmin,
  helpCenter,
  actionHandlers,
  classes
}) => {
  const translations = useContext(TranslationContext);
  const hasHelpCustomAction =
    actionHandlers && actionHandlers.onHelp ? true : false;
  const hasAdminCustomAction =
    actionHandlers && actionHandlers.onAdmin ? true : false;
  const sortedNavigation = navigationSortKey ? sort_by_key(navigation, navigationSortKey) : sort_by_key(navigation, 'name');
  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="right"
      open={isOpen}
      onClose={handleToggle(false)}
    >
      <div className={classes.sidemenu}>
        <SideMenuHeader handleClose={handleToggle(false)} />
        <nav
          aria-labelledby="menu-button"
          id="pl-menu-nav"
          aria-hidden={!isOpen}
        >
          <List id="pl-menu-list">
            <ListItem
              className={[classes.item, (!appObj && currentNavigation && currentNavigation === translations.home ? classes.selectedItem : '')].join(" ")}
              button
              disableGutters
              key="/"
              href="/"
              component="a"
              id="pl-menu-item"
            >
              <ListItemIcon className={classes.icon}>
                <AdjustIcon />
              </ListItemIcon>
              <ListItemText
                primary={translations.home}
                classes={{
                  primary: classes.text,
                  root: classes.textWrapper
                }}
                id="pl-menu-item-text"
              />
            </ListItem>
            {sortedNavigation.map(item => {
              return (
                <ListItem
                  className={[classes.item, (appObj && appObj.clientId === item.clientId ? classes.selectedItem : '')].join(" ")}
                  button
                  disableGutters
                  key={item.applicationUrl}
                  href={item.applicationUrl}
                  component="a"
                  id="pl-menu-item"
                >
                  <ListItemIcon className={classes.icon}>
                    <AdjustIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    classes={{
                      primary: classes.text,
                      root: classes.textWrapper
                    }}
                    id="pl-menu-item-text"
                  />
                </ListItem>
              );
            })}
          </List>
        </nav>
        {(analyzerAdmin || helpCenter) && (
          <nav id="pl-menu-extra-nav">
          <List className={classes.extraList} id="pl-menu-extra-list">
            {analyzerAdmin && (
              <ListItem
                className={[classes.item, (!appObj && currentNavigation && currentNavigation === translations.admin ? classes.selectedItem : '')].join(" ")}
                button
                disableGutters
                key="url-admin"
                href={hasAdminCustomAction ? undefined : "analyzer-admin"}
                onClick={hasAdminCustomAction ? actionHandlers.onAdmin : null}
                component="a"
                id="pl-menu-extra-item"
              >
                <ListItemIcon className={classes.extraIcon}>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <ListItemText
                  primary={translations.admin}
                  classes={{
                    primary: classes.text,
                    root: classes.textWrapper
                  }}
                  id="pl-menu-extra-item-text"
                />
              </ListItem>
            )}
            {helpCenter && (
              <ListItem
              className={[classes.item, (!appObj && currentNavigation && currentNavigation === translations.help ? classes.selectedItem : '')].join(" ")}
              button
              disableGutters
              key="url-help"
              href={hasHelpCustomAction ? undefined : "/helpcenter"}
              onClick={hasHelpCustomAction ? actionHandlers.onHelp : null}
              component="a"
              id="pl-menu-extra-item"
            >
              <ListItemIcon className={classes.extraIcon}>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                primary={translations.help}
                classes={{
                  primary: classes.text,
                  root: classes.textWrapper
                }}
                id="pl-menu-extra-item-text"
              />
            </ListItem>
            )}
          </List>
        </nav>
        )}
      </div>
    </Drawer>
  );
};

SideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  navigation: PropTypes.array.isRequired,
  navigationSortKey: PropTypes.string,
  currentNavigation: PropTypes.string,
  helpCenter: PropTypes.bool,
  analyzerAdmin: PropTypes.bool,
  actionHandlers: PropTypes.object
};

SideMenu.defaultProps = {
  analyzerAdmin: false,
  helpCenter: false
};

export default withStyles(styles)(SideMenu);
