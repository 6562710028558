import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const TimePeriodWarning = ({ message }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: 20,
      color: "#bb5739"
    }}
  >
    <WarningIcon style={{ paddingRight: 5 }} />
    <Typography variant="body1">{message}</Typography>
  </div>
);

TimePeriodWarning.propTypes = {
  message: PropTypes.string.isRequired
};

export default TimePeriodWarning;
