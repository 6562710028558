import { createTheme } from "@material-ui/core/styles";
import * as colors from "./colors";
const defaultColor = colors.grey.dark;

export default createTheme({
  palette: {
    primary: {
      light: colors.blue[100],
      main: colors.blue.superDark,
      dark: colors.blue.superDark
    }
  },
  status: {
    grey: colors.grey,
    blue: colors.blue,
    orange: colors.orange,
    yellow: colors.yellow
  },
  overrides: {
    MuiButtonBase: {
      root: {
        border: "none",
        backgroundImage: "none",
        "&:hover": {
          border: "none",
          backgroundImage: "none"
        },
        "&:focus": {
          border: "none",
          backgroundImage: "none"
        }
      }
    },
    MuiButton: {
      label: {
        textShadow: "none"
      },
      contained: {
        boxShadow: "none",
        transition: "none",
        paddingRight: 25,
        paddingLeft: 25,
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 30,
        fontSize: 14,
        borderRadius: "3.125rem"
      },
      containedPrimary: {
        color: colors.grey.white,
        backgroundColor: colors.blue.superDark,

        "&:hover": {
          backgroundColor: colors.yellow.emnos,
          color: colors.blue.superDark
        },
        "&:active": {
          backgroundColor: colors.yellow.emnos,
          color: colors.blue.superDark,
          boxShadow: "none"
        }
      }
    },
    MuiInput: {
      root: {
        color: colors.grey.dark,
        fontSize: 14
      },
      underline: {
        "&:after": {
          borderBottomColor: colors.blue.dark
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: colors.blue.dark
        }
      }
    }
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    color: defaultColor,
    display1: {
      color: defaultColor,
      fontSize: "2.25rem",
      lineHeight: 3.125
    },
    headline: {
      color: defaultColor,
      fontSize: "1.75rem",
      lineHeight: 2.5
    },
    title: {
      color: defaultColor,
      fontSize: "1.25rem",
      lineHeight: 2.18
    },
    subheading: {
      color: defaultColor,
      fontSize: "1.125rem",
      lineHeight: 1.875
    },
    body1: {
      color: defaultColor,
      fontSize: "1rem",
      lineHeight: 1.56
    },
    body2: {
      color: defaultColor,
      fontSize: "0.875rem",
      lineHeight: 1.56
    },
    caption: {
      color: defaultColor,
      fontSize: "0.75rem",
      lineHeight: 1.25
    },
    button: {
      fontSize: "0.75rem",
      textTransform: "uppercase",
      fontWeight: 600,
      lineHeight: "1.25rem"
    }
  }
});
