import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";

import ToggleButton from "./ToggleButton";

const TimePeriodToggleGroup = ({ periods, period, handleChange }) => (
  <FormControl component="fieldset">
    {periods.map((el, index) => (
      <ToggleButton
        key={index}
        selected={period === el}
        onClick={() => handleChange(el)}
      >
        {el.label}
      </ToggleButton>
    ))}
  </FormControl>
);

TimePeriodToggleGroup.propTypes = {
  periods: PropTypes.array.isRequired,
  period: PropTypes.shape({ label: PropTypes.string, weeks: PropTypes.number }),
  handleChange: PropTypes.func.isRequired
};

export default TimePeriodToggleGroup;
