import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./ListToggleButton.module.css";

export default function ListToggleButton({ handleClick, expanded }) {
  return (
    <button
      className={classNames(
        {
          [styles.toggleButtonExpanded]: expanded
        },
        styles.toggleButton
      )}
      onClick={handleClick}
    >
      <svg width="24" height="24" viewBox="0 0 24 24">
        <rect fill="currentColor" x="3" y="9" width="8" height="2"></rect>
        {!expanded && (
          <rect fill="currentColor" x="6" y="6" width="2" height="8"></rect>
        )}
      </svg>
    </button>
  );
}

ListToggleButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired
};
