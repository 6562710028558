// React
import React, { useContext } from "react";
import PropTypes from "prop-types";

// Material UI
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

// Context
import TranslationContext from "../../../context/TranslationContext";

// Styles
const styles = theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  menuIcon: {
    marginTop: 9
  },
  title: {
    marginTop: "0.5rem",
    marginLeft: "1rem",
    flexBasis: "100%",
    textTransform: "capitalize"
  },
  close: {
    right: -7,
    marginTop: 5
  },
  closeIcon: {
    "&:hover": {
      color: theme.status.blue.superDark
    }
  }
});

const SideMenuHeader = ({ menuTitle, handleClose, classes }) => {
  const translations = useContext(TranslationContext);
  return (
    <div className={classes.header}>
      <MenuIcon className={classes.menuIcon} />
      <Typography
        variant="subtitle1"
        component="h2"
        className={classes.title}
        id="pl-menu-title"
      >
        {translations.menu}
      </Typography>
      <IconButton
        onClick={handleClose}
        className={classes.close}
        aria-label="close"
        id="pl-menu-close-icon"
      >
        <CloseIcon className={classes.closeIcon} />
      </IconButton>
    </div>
  );
};

SideMenuHeader.propTypes = {
  menuTitle: PropTypes.string,
  handleClose: PropTypes.func
};

SideMenuHeader.defaultProps = {
  menuTitle: "menu",
  handleClose: () => {}
};

export default withStyles(styles)(SideMenuHeader);
