// React
import React, { useContext } from "react";
import PropTypes from "prop-types";

// Material UI
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import SettingsIcon from '@material-ui/icons/Settings';

// Components
import CustomButton from "../../CustomButton/CustomButton";
import TranslationContext from "../../../context/TranslationContext";

// Generate user initials
export const getUserInitials = name => {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  return initials;
};

// Styles
const styles = theme => ({
  accountWrapper: {
    padding: 35
  },
  avatarWrapper: {
    marginRight: 20
  },
  name: {
    marginBottom: 13,
    fontFamily: "'Open Sans SemiBold', sans-serif",
    textTransform: "capitalize"
  },
  fixedname: {
    marginBottom: 13,
    fontFamily: "'Open Sans SemiBold', sans-serif",
    textTransform: "capitalize",
    width: 160,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  avatar: {
    margin: 0,
    width: 80,
    height: 80,
    fontSize: "2rem",
    color: theme.status.grey.white,
    backgroundColor: theme.status.orange[100]
  },
  settings: {
    fontSize: "0.875rem",
    borderRadius: "3.125rem",
    backgroundColor: theme.status.blue.superDark,
    color: theme.status.grey.white,
    left: "-5px",
    "&:hover": {
      backgroundColor: theme.status.yellow.emnos,
      color: theme.status.blue.superDark
    }
  }
});

export function getUserName(userName) {
  if (userName.length > 20) {
    return (
      <Tooltip title={userName} placement="top-start" id="tooltip-top-start">
        <span>{userName}</span>
      </Tooltip>
    );
  } else return userName;
}

export function getTitleClass(userName, classes) {
  if (userName.length > 20) {
    return classes.fixedname;
  } else {
    return classes.name;
  }
}

const AccountHeader = ({ userName, actionHandlers, classes }) => {
  const translations = useContext(TranslationContext);
  const user = getUserName(userName);
  const titleClass = getTitleClass(userName, classes);
  return (
    <CardHeader
      classes={{
        root: classes.accountWrapper,
        avatar: classes.avatarWrapper,
        title: titleClass
      }}
      avatar={
        <Avatar alt="avatar" className={classes.avatar}>
          {getUserInitials(userName)}
        </Avatar>
      }
      title={user}
      subheader={
        <CustomButton
          className={classes.settings}
          actionHandlers={actionHandlers}
          action="onUserSettings"
          url="/portal/settings"
          id="pl-settings"
        >
          <SettingsIcon style={{ marginRight: "5px" }} />
          {translations.settings}
        </CustomButton>
      }
      maxcount={userName.length > 10 ? "true" : "false"}
    />
  );
};

AccountHeader.prototype = {
  userName: PropTypes.string,
  actionHandlers: PropTypes.object
};

AccountHeader.defaultProps = {
  userName: "Portal team"
};

export default withStyles(styles)(AccountHeader);
