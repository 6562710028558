import React from "react";
import styled, { keyframes } from "styled-components";

const spinAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const StyledLoader = styled.div`
  text-align: center;
  font-family: "Open Sans", Arial;
  font-size: 10px;
  color: #5f5f5f;
  box-sizing: content-box;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #0076bc;
  animation: ${spinAnimation} 2s linear infinite;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #cdcdcd;
    animation: ${spinAnimation} 3s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00b4e6;
    animation: ${spinAnimation} 1.5s linear infinite;
  }
`;

export default StyledLoader;
