import React from "react";
import PropTypes from "prop-types";

import SearchIcon from "./SearchIcon";

import styles from "./Button.module.css";

export default function SearchToggleButton({ handleClick }) {
  return (
    <button
      className={styles.button}
      styles={{ outline: "none" }}
      onClick={handleClick}
    >
      <SearchIcon />
    </button>
  );
}

SearchToggleButton.propTypes = {
  handleClick: PropTypes.func.isRequired
};
