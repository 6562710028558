import React, { useState, useCallback, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import ListToggleButton from "./ListToggleButton";
import SearchToggleButton from "./SearchToggleButton";
import FileFolderIcon from "./FileFolderIcon";
import FileFolderLabel from "./FileFolderLabel";

import SearchInput from "./SearchInput";

import styles from "./ListItem.module.css";
import { useDimensions } from "./useDimensions";

const TOOLTIP_DELAY = 1000;

function renderName(memberKey, hierarchyNamePrefix, hierarchyName) {
  let str = hierarchyName;
  if (hierarchyNamePrefix) {
    str = `${hierarchyNamePrefix} - ${str}`;
  }
  if (memberKey) {
    str = `${memberKey} ${str}`;
  }
  return str;
}

function getTooltipStyles(dimensions) {
  // centred above
  const left = dimensions.x + dimensions.width * 0.5;
  const top = dimensions.y - dimensions.height - 2;
  // fixed position
  return { position: "fixed", top, left };
}

export default function ListItem({
  data,
  expanded,
  handleClickToggle,
  handleClickLabel,
  memberKey,
  hierarchyName,
  hierarchyNamePrefix,
  userHierarchyNode,
  dynamicHierarchyNode,
  children,
  selected,
  link,
  leaf,
  nodes,
  perPage
}) {
  // dimensions hook for popup
  const [ref, dimensions] = useDimensions();

  // state of mouse, inside or out ?
  const [entered, setEntered] = useState(false);

  // showing tooltip?
  const [tooltip, setTooltip] = useState(false);

  // enhance child nodes with data
  const enhancedNodes = useMemo(() => nodes.map(id => data[id]));
  // search open/closed state
  const [showSearchInput, setShowSearchInput] = useState(false);

  // should show children list? el.childrenIds.length > 0 && el.expanded;
  const showExpanded = useMemo(() => nodes.length > 0 && expanded, [
    nodes.length,
    expanded
  ]);

  // search string value state
  const [searchValue, setSearch] = useState("");

  // search input callback
  const handleChangeSearch = useCallback(e => {
    setSearch(e.target.value);
  }, []);

  // toggle search input field callback
  const handleToggleSearch = useCallback(() => {
    setShowSearchInput(!showSearchInput);
    setSearch("");
  }, [showSearchInput]);

  const handleMouseEnter = event => {
    event.preventDefault();
    setEntered(true);
  };

  const handleMouseLeave = event => {
    event.preventDefault();
    setEntered(false);
    setTooltip(false);
  };

  useEffect(() => {
    let timeout;
    if (entered) {
      timeout = setTimeout(() => {
        setTooltip(true);
      }, TOOLTIP_DELAY);
    }
    return () => clearTimeout(timeout);
  }, [entered]);

  // filter descendant nodes by search value
  const searchFilteredNodes = useMemo(() => {
    const re = new RegExp(searchValue, "i");
    return enhancedNodes.filter(
      el => re.test(el.hierarchyName) || re.test(el.memberKey)
    );
  }, [enhancedNodes, searchValue]);

  const childrenNodeIds = useMemo(() => searchFilteredNodes.map(el => el.id), [
    searchFilteredNodes
  ]);

  const isPaginated = useMemo(() => {
    return Array.isArray(enhancedNodes) && enhancedNodes.length > perPage;
  }, [enhancedNodes, perPage]);

  return (
    <li
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.listItem}
      role="tree-item"
    >
      {tooltip &&
        leaf &&
        ReactDOM.createPortal(
          <div className={styles.tooltip} style={getTooltipStyles(dimensions)}>
            {renderName(memberKey, hierarchyNamePrefix, hierarchyName)}
          </div>,
          document.body
        )}
      <div
        className={classNames(
          {
            [styles.listHeaderLeaf]: leaf
          },
          styles.listHeader
        )}
      >
        {!leaf && (
          <ListToggleButton
            expanded={expanded}
            handleClick={handleClickToggle}
          />
        )}
        <FileFolderIcon
          link={link}
          leaf={leaf}
          expanded={expanded}
          userHierarchyNode={userHierarchyNode}
          dynamicHierarchyNode={dynamicHierarchyNode}
        />
        <FileFolderLabel
          ref={ref}
          selected={selected}
          handleClick={handleClickLabel}
        >
          {renderName(memberKey, hierarchyNamePrefix, hierarchyName)}
        </FileFolderLabel>
        {isPaginated && !leaf && expanded && (
          <>
            <SearchToggleButton handleClick={handleToggleSearch} />
            {showSearchInput && (
              <SearchInput
                handleChange={handleChangeSearch}
                searchValue={searchValue}
              />
            )}
          </>
        )}
      </div>
      {showExpanded &&
        children({
          childrenNodeIds
        })}
    </li>
  );
}

ListItem.propTypes = {
  data: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  handleClickToggle: PropTypes.func.isRequired,
  handleClickLabel: PropTypes.func.isRequired,
  hierarchyName: PropTypes.string.isRequired,
  hierarchyNamePrefix: PropTypes.string,
  memberKey: PropTypes.string,
  children: PropTypes.any,
  leaf: PropTypes.bool.isRequired,
  link: PropTypes.bool.isRequired,
  userHierarchyNode: PropTypes.bool.isRequired,
  dynamicHierarchyNode: PropTypes.bool.isRequired,
  nodes: PropTypes.array,
  perPage: PropTypes.number.isRequired
};
