import React from "react";
import PropTypes from "prop-types";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import BackArrow from "@material-ui/icons/KeyboardBackspace";

const BackArrowOutlined = ({ folderColour }) => (
  <>
    <BackArrow
      style={{
        position: "absolute",
        left: 2,
        top: 9,
        width: "0.75em",
        height: "0.75em"
      }}
      htmlColor={"#ffffff"}
    />
    <BackArrow
      style={{
        position: "absolute",
        left: 2,
        top: 11,
        width: "0.75em",
        height: "0.75em"
      }}
      htmlColor={folderColour}
    />
  </>
);

BackArrowOutlined.propTypes = {
  folderColour: PropTypes.string.isRequired
};

const FileFolderIcon = ({
  leaf,
  expanded,
  userHierarchyNode,
  dynamicHierarchyNode
}) => {
  const folderColour = leaf
    ? "#929292"
    : userHierarchyNode
    ? "#2643BC"
    : "#59B0E1";
  const FileFolder = leaf
    ? DescriptionIcon
    : expanded
    ? FolderOpenIcon
    : FolderIcon;
  return (
    <div style={{ position: "relative" }}>
      <FileFolder htmlColor={folderColour} />
      {!leaf && dynamicHierarchyNode && (
        <BackArrowOutlined folderColour={folderColour} />
      )}
    </div>
  );
};

FileFolderIcon.propTypes = {
  leaf: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  userHierarchyNode: PropTypes.bool.isRequired,
  dynamicHierarchyNode: PropTypes.bool.isRequired
};

export default FileFolderIcon;
