import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import Loading from "../../assets/emnos_loader.gif";

import Loader from "./Loader";

// Attach to modal root element
const modalRoot = window.modalRootElementId
  ? document.getElementById(modalRootElementId)
  : document.querySelector("body");

const StyledOverlay = styled.div`
  z-index: 10000000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);
`;

const LoaderOverlay = () =>
  ReactDOM.createPortal(
    <StyledOverlay id="overlay-icon">
      {/*<Loader />*/}
      <img src={Loading} alt="loading" />
    </StyledOverlay>,
    modalRoot
  );

export default LoaderOverlay;
