// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2vUB5VCLvkWV_kxk-W5-Xc {\n  padding-left: 4px;\n  color: #777;\n  outline-style: none;\n  outline-color: white;\n  outline-width: thin;\n  background-color: transparent;\n  user-select: none;\n}\n\n._2vUB5VCLvkWV_kxk-W5-Xc:hover {\n  color: #000;\n}\n\n._2kaU-CLLVI2FtnXaFOn0ju {\n  color: #000;\n  outline-style: dotted;\n  background-color: lightblue;\n}\n", ""]);
// Exports
exports.locals = {
	"label": "_2vUB5VCLvkWV_kxk-W5-Xc",
	"label-selected": "_2kaU-CLLVI2FtnXaFOn0ju",
	"labelSelected": "_2kaU-CLLVI2FtnXaFOn0ju"
};
module.exports = exports;
