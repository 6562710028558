import { useCallback, useState } from "react";

const defaultDimensions = {
  x: 0,
  y: 0,
  width: 0,
  height: 0
};

function transformRect(rect) {
  return {
    x: rect.left,
    y: rect.top,
    width: rect.width,
    height: rect.height
  };
}

export function useDimensions() {
  const [state, setState] = useState(defaultDimensions);

  const ref = useCallback(
    ref => ref && setState(transformRect(ref.getBoundingClientRect())),
    []
  );

  return [ref, state];
}
