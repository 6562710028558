// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import { MuiThemeProvider } from "@material-ui/core/styles";

// Context
import TranslationContext from "../../context/TranslationContext";
import { getTranslationsByLocale } from "../../context/translations";
import theme from "../../theme/index";

import HeaderContent from "./HeaderContent";

const Header = React.forwardRef(function Header(props, ref) {
  const { locale, ...remainingProps } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <TranslationContext.Provider
        value={getTranslationsByLocale(
          remainingProps.emnosPortalAssets,
          locale
        )}
      >
        <>
          <HeaderContent ref={ref} {...remainingProps} />
        </>
      </TranslationContext.Provider>
    </MuiThemeProvider>
  );
});

Header.propTypes = {
  emnosPortalAssets: PropTypes.shape({
    translations: PropTypes.object,
    actionHandlers: PropTypes.shape({
      onSubmitFeedback: PropTypes.func,
      onSubmitTrackingConsent: PropTypes.func,
      onUserLogoout: PropTypes.func,
      onUserSettings: PropTypes.func,
      onHelp: PropTypes.func,
      onAdmin: PropTypes.func
    })
  }),
  retailerName: PropTypes.oneOf([
    "emnos",
    "pdo_pt",
    "crf_be",
    "crf_fr",
    "gel_us",
    "len_ru",
    "bie_pl",
    "gig_at",
    "mag_ru"
  ]),
  locale: PropTypes.string,
  appName: PropTypes.string,
  appClientId: PropTypes.string,
  data: PropTypes.shape({
    userName: PropTypes.string,
    comEmail: PropTypes.string,
    navigation: PropTypes.array,
    navigationSortKey: PropTypes.string,
    currentNavigation: PropTypes.string,
    showTrackingPopUp: PropTypes.bool
  }),
  maxWidth: PropTypes.string,
  notToLoadHeaderActions: PropTypes.bool
};

Header.defaultProps = {
  appName: "AppName",
  retailerName: "emnos",
  locale: "en_US",
  notToLoadHeaderActions: false
};

export default Header;
