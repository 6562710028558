// Material UI
import { createTheme } from "@material-ui/core/styles";

// Colors
import * as colors from "./colors";
const defaultColor = colors.grey.dark;
const primaryColor = colors.blue.superDark;
const secondaryColor = colors.red[100];

export default createTheme({
  palette: {
    primary: {
      light: colors.blue[100],
      main: primaryColor,
      dark: primaryColor
    },
    secondary: {
      light: secondaryColor,
      main: secondaryColor,
      dark: colors.red.dark
    },

    error: {
      main: secondaryColor
    },
    action: {
      active: defaultColor
    },
    text: {
      divider: "#d8d8d8"
    },
    type: "light",
    background: {
      appBar: "#ffffff"
    },
    shades: {
      light: {
        text: {
          icon: "#ff0000",
          primary: defaultColor
        }
      }
    }
  },
  status: {
    blue: colors.blue,
    grey: colors.grey,
    green: colors.green,
    red: colors.red,
    cyan: colors.cyan,
    mauve: colors.mauve,
    pink: colors.pink,
    orange: colors.orange,
    yellow: colors.yellow,
    teal: colors.teal,
    clay: colors.clay
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    color: defaultColor,
    h4: {
      color: defaultColor,
      fontSize: "2.25rem",
      lineHeight: 3.125
    },
    h5: {
      color: defaultColor,
      fontSize: "1.75rem",
      lineHeight: 2.5
    },
    h6: {
      color: defaultColor,
      fontSize: "1.25rem",
      lineHeight: 2.18
    },
    subtitle1: {
      color: defaultColor,
      fontSize: "1.125rem",
      lineHeight: 1.875
    },
    body2: {
      color: defaultColor,
      fontSize: "1rem",
      lineHeight: 1.56
    },
    body1: {
      color: defaultColor,
      fontSize: "0.875rem",
      lineHeight: 1.56
    },
    caption: {
      color: defaultColor,
      fontSize: "0.75rem",
      lineHeight: 1.25
    },
    button: {
      fontSize: "0.75rem",
      textTransform: "uppercase",
      fontWeight: 600,
      lineHeight: "1.25rem"
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.08)"
      }
    },
    MuiTabs: {
      root: {
        boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.08)"
      },
      indicator: {
        height: "0"
      }

    },
    MuiTab: {
      root: {
        maxWidth: "50%",
        flexGrow: 1
      },
      textColorPrimary: {
        color: defaultColor,
        textTransform: "capitalize",
        fontWeight: "normal",
        lineHeight: 1,
        height: 59,
        background: colors.grey.greyBox,
        borderBottom: `2px solid ${colors.grey.greyDivs}`,
        "&$selected": {
          color: colors.grey.white,
          background: colors.blue.superDark,
          borderBottom: "none",
          borderRadius: "0 3.125rem 0 0",
          fontSize: "1.25rem"
        }
      }
    },
    MuiRadio: {
      colorPrimary: {
        "&$checked": {
          color: colors.blue.superDark
        }
      }
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        transition: "none",
        paddingRight: 25,
        paddingLeft: 25,
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 30,
        fontSize: 14,
        borderRadius: "3.125rem"
      },
      containedPrimary: {
        color: colors.grey.white,
        backgroundColor: colors.blue.superDark,

        "&:hover": {
          backgroundColor: colors.yellow.emnos,
          color: colors.blue.superDark
        },
        "&:active": {
          backgroundColor: colors.yellow.emnos,
          color: colors.blue.superDark,
          boxShadow: "none"
        }
      },
      outlinedPrimary: {
        borderRadius: "3.125rem",
        color: colors.blue.superDark,
        borderColor: colors.blue.superDark,

        "&:hover": {
          backgroundColor: "rgb(248,231,28,0.5)"
        }
      },
      textPrimary: {
        boxShadow: "none",
        transition: "none",
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 20,
        fontSize: 12,
        color: colors.grey.white,
        backgroundColor: colors.blue.dark,

        "&:hover": {
          backgroundColor: colors.blue.extraDark
        },
        "&:active": {
          backgroundColor: colors.blue.extraDark
        }
      },
      outlined: {
        paddingRight: 25,
        paddingLeft: 25,
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 30,
        fontSize: 14,
        border: `1px solid ${colors.blue[40]}`,
        boxShadow: "none",
        backgroundColor: colors.grey.white,
        color: colors.blue.dark,

        "&:hover": {
          backgroundColor: colors.grey.white,
          borderColor: colors.blue.dark
        },
        "&:active": {
          backgroundColor: colors.grey.white,
          borderColor: colors.blue[20]
        }
      },
      outlinedSecondary: {
        border: `1px solid ${colors.blue[40]}`,
        "&:hover": {
          backgroundColor: colors.grey.white,
          borderColor: colors.blue.dark
        },
        "&:active": {
          backgroundColor: colors.grey.white,
          borderColor: colors.blue[20]
        }
      }
    },
    MuiInput: {
      root: {
        color: colors.grey.dark,
        fontSize: 14
      },
      underline: {
        "&:after": {
          borderBottomColor: colors.blue.dark
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottomColor: colors.blue.dark
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: colors.grey.dark,
        fontSize: 14,
        opacity: 0.5
      }
    },
    MuiTypography: {
      colorTextSecondary: {
        color: colors.grey.white
      },
      gutterBottom: {
        marginBottom: 15
      }
    },
    MuiDialog: {
      paper: {
        borderRadius: "0 3.125rem"
      }
    },
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          transform: "translate(30px)",
          color: colors.blue.superDark,
          "& + $bar": {
            backgroundColor: colors.grey.greyBox,
            boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.2)"
          },
          "& + $track": {
            backgroundColor: colors.blue.superDark
          }
        }
      },
      root: {
        width: 65.5
      },
      switchBase: {
        color: colors.grey.greyDivs
      },
      bar: {
        borderRadius: 13,
        width: 55.5,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        backgroundColor: colors.grey.greyBox,
        boxShadow: "inset 0 1px 4px 0 rgba(0, 0, 0, 0.2)"
      },
      icon: {
        width: 24,
        height: 24,
        boxShadow: "none"
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.grey.white,
        color: defaultColor,
        boxShadow:
          "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.1)",
        padding: 10,
        "& .arrowArrow": {
          position: "absolute",
          fontSize: 7,
          width: "3em",
          height: "3em",
          "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid"
          },
          "&::after": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            top: "-.9em",
            zIndex: -1,
            position: "relative",
            borderStyle: "solid"
          }
        }
      },
      tooltipPlacementBottom: {
        "& .arrowArrow": {
          top: 0,
          left: 0,
          marginTop: "-0.9em",
          width: "3em",
          height: "1em",
          "&::before": {
            borderWidth: "0 1em 1em 1em",
            borderColor: `transparent transparent ${
              colors.grey.white
              } transparent`
          },
          "&::after": {
            borderWidth: "0 1em 1em 1em",
            borderColor: `transparent transparent rgba(0, 0, 0, 0.3) transparent`,
            top: "-1em"
          }
        }
      },
      tooltipPlacementTop: {
        "& .arrowArrow": {
          bottom: 0,
          left: 0,
          marginBottom: "-0.9em",
          width: "3em",
          height: "1em",
          "&::before": {
            borderWidth: "1em 1em 0 1em",
            borderColor: `${
              colors.grey.white
              } transparent transparent transparent`
          },
          "&::after": {
            borderWidth: "1em 1em 0 1em",
            borderColor: `rgba(0, 0, 0, 0.3) transparent transparent transparent`,
            top: "-1em"
          }
        }
      },
      popper: {
        opacity: 1
      }
    }
  },
  mixins: {
    header: {
      width: "1440px"
    },
    footer: {
      background: "#333333",
      color: "#ffffff"
    }
  }
});
