import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./Button.module.css";

const DEFAULT_MAX_PAGE_OPTIONS = 5;

export function initPages(totalPages) {
  const pages = [];
  for (let i = 0; i < totalPages; ++i) {
    pages.push(i + 1);
  }
  return pages;
}

export function getPages(
  page,
  totalPages,
  maxPageOptions = DEFAULT_MAX_PAGE_OPTIONS
) {
  let pages = initPages(totalPages);
  const maxPageCentre = parseInt(maxPageOptions / 2, 10);
  let startIndex = page < maxPageCentre ? 0 : page - maxPageCentre;
  startIndex =
    startIndex + maxPageOptions > totalPages
      ? totalPages - maxPageOptions
      : startIndex;
  startIndex = startIndex < 0 ? 0 : startIndex;
  const endIndex = startIndex + maxPageOptions;
  pages = pages.slice(startIndex, endIndex);
  return pages;
}

export default function ListPagination({
  page,
  totalPages,
  handlePrev,
  handleNext,
  handleSelectPage
}) {
  if (totalPages < 2) {
    return null;
  }
  const pages = getPages(page, totalPages);
  return (
    <div className={styles.pagination}>
      <button
        className={styles.paginationButton}
        disabled={page === 0}
        onClick={handlePrev}
      >
        &lt;
      </button>
      {pages.length &&
        pages.map(el => (
          <button
            className={classNames(
              {
                [styles.paginationButtonActive]: page === el - 1
              },
              styles.paginationButton
            )}
            key={el}
            onClick={() => handleSelectPage(el - 1)}
          >
            {el}
          </button>
        ))}
      <button
        className={styles.paginationButton}
        disabled={page === totalPages - 1}
        onClick={handleNext}
      >
        &gt;
      </button>
    </div>
  );
}

ListPagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleSelectPage: PropTypes.func.isRequired
};
