// React
import React, { useState, useContext, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

// Material UI
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Link } from "@material-ui/core";
import AssessmentIcon from '@material-ui/icons/Assessment';
import { withStyles } from "@material-ui/core/styles";

// Context
import TranslationContext from "../../context/TranslationContext";

// Components


// styles
const styles = theme => ({
  wrapper: {
    borderRadius: "0 3.125rem",
    minWidth: 700,
    maxHeight: 550,
    zIndex: 100
  },
  title: {
    padding: "1.3rem",
    paddingLeft: 35,
    color: theme.status.grey.white,
    backgroundColor: theme.status.blue.superDark,
    "&& .MuiTypography-h6": {
      color: theme.status.grey.white
    }
  },
  content: {
    padding: 35
  },
  actions: {
    marginBottom: 20,
    marginRight: 35,
    marginTop: 15
  },
  highlight: {
    marginBottom: "0.5rem",
    fontSize: "1rem",
    lineHeight: 1.56,
    fontWeight: 650,
    color: theme.status.grey.dark
  },
  text: {
    marginBottom: "0.5rem",
    fontSize: "1rem",
    lineHeight: 1.56,
    color: theme.status.grey.dark
  },
  list: {
    marginBottom: "0.5rem",
    fontSize: "1rem",
    lineHeight: 1.56,
    marginLeft: 20,
    color: theme.status.grey.dark
  },
  input: {
    width: "100%"
  },
  link: {
    fontWeight: 700,
    paddingRight: 15,
    textTransform: "uppercase",
    color: theme.status.grey[100]
  },
  textLink: {
    color: theme.status.blue.dark,
    display: "inline-block",
    margin: "0 0.2rem"
  },
  close: {
    position: "absolute",
    right: "1.5rem",
    top: "1.5rem",
    color: theme.status.grey.white,
    "&&:hover": {
      color: theme.status.blue.superDark,
      backgroundColor: theme.status.grey.greyDivs
    }
  },
  closeIcon: {
    fontSize: "1rem"
  }
});

const TrackingPopUp = ({ isOpen, isMoreInfo, handleMoreInfo, handleReject, handleAccept, classes }) => {

  const translations = useContext(TranslationContext);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.wrapper }}
      id="pl-dialog-wrapper"
    >
      <DialogTitle id="pl-dialog-title" classes={{ root: classes.title }}>
        {translations.tracking_title}
        <AssessmentIcon style={{ height: 20, marginTop: -3, verticalAlign: "middle", paddingLeft: 5 }} />
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text">
          {translations.tracking_intro}
        </DialogContentText>
        <DialogContentText classes={{ root: classes.highlight }} id="pl-dialog-text1">
          {translations.tracking_body1}
        </DialogContentText>
        <DialogContentText classes={{ root: classes.highlight }} id="pl-dialog-text2">
          {translations.tracking_body2}
        </DialogContentText>
        {isMoreInfo && (
          <div>
            <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text3">
              {translations.tracking_body3}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text31">
              {translations.tracking_list1}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text32">
              {translations.tracking_list2}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text33">
              {translations.tracking_list3}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text34">
              {translations.tracking_list4}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text35">
              {translations.tracking_list5}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text4">
              {translations.tracking_body4}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text351">
              {translations.tracking_list51}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text352">
              {translations.tracking_list52}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text353">
              {translations.tracking_list53}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text354">
              {translations.tracking_list54}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text355">
              {translations.tracking_list55}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text5">
              {translations.tracking_body5}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3551">
              {translations.tracking_list551}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3552">
              {translations.tracking_list552}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3553">
              {translations.tracking_list553}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3554">
              {translations.tracking_list554}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3555">
              {translations.tracking_list555}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3556">
              {translations.tracking_list556}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3557">
              {translations.tracking_list557}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3558">
              {translations.tracking_list558}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text3559">
              {translations.tracking_list559}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.list }} id="pl-dialog-text35510">
              {translations.tracking_list5510}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text6">
              {translations.tracking_body6}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text7">
              {translations.tracking_body7}
            </DialogContentText>
            <DialogContentText classes={{ root: classes.text }} id="pl-dialog-text8">
              {translations.tracking_body_tail}
              <a href={window.origin + "/portal/data-privacy"} target="_blank" rel="noopener noreferrer" className={classes.textLink}>
                Data Protection Notice
              </a>
            </DialogContentText>
          </div>)}
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        {!isMoreInfo && (<Link
          component="button"
          variant="body2"
          onClick={() => handleMoreInfo() }
          id="pl-dialog-button-more"
          underline="none"
          classes={{ root: classes.link }}
        >
          {translations.more_info}
        </Link>)}
        {isMoreInfo && (<Link
          component="button"
          variant="body2"
          onClick={() => handleReject() }
          id="pl-dialog-button-reject"
          underline="none"
          classes={{ root: classes.link }}
        >
          {translations.reject}
        </Link>)}
        <Button
          onClick={() => handleAccept() }
          color="primary"
          variant="contained"
          type="submit"
          size="large"
          id="pl-dialog-button-accept"
        >
          {translations.accept}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TrackingPopUp.propTypes = {
  isOpen: PropTypes.bool,
  isMoreInfo: PropTypes.bool,
  handleMoreInfo: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired
};

TrackingPopUp.defaultProps = {
  isOpen: false,
  isMoreInfo: false
};

export default withStyles(styles)(TrackingPopUp);
