import React from "react";
import PropTypes from "prop-types";

import { KeyboardDatePicker } from "@material-ui/pickers";
import RightArrowIcon from "@material-ui/icons/ChevronRight";
import LeftArrowIcon from "@material-ui/icons/ChevronLeft";
import DateRangeIcon from "@material-ui/icons/DateRange";

const TimePeriodDatePicker = ({
  label,
  dateValue,
  handleChange,
  minDate,
  maxDate
}) => (
  <div style={{ marginBottom: 10 }}>
    <KeyboardDatePicker
      label={label}
      format="L"
      value={dateValue}
      onChange={handleChange}
      maxDate={maxDate}
      minDate={minDate}
      initialFocusedDate={minDate}
      animateYearScrolling={false}
      rightArrowIcon={<RightArrowIcon />}
      leftArrowIcon={<LeftArrowIcon />}
      keyboardIcon={<DateRangeIcon />}
    />
  </div>
);

TimePeriodDatePicker.propTypes = {
  label: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  maxDate: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  dateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

TimePeriodDatePicker.defaultProps = {
  label: "Date picker label"
};

export default TimePeriodDatePicker;
