// React
import React, { Component } from "react";
import PropTypes from "prop-types";
// Material UI
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// Styles
import theme from "../../theme/MaterialTheme";
import styled from "styled-components";
import { MuiThemeProvider } from "@material-ui/core";

const RootContainer = styled(Grid)`
  background: ${theme.status.blue.superDark};
  text-align:center;
`;

const Container = styled(Grid)`
  background: ${theme.status.blue.superDark};
  flex: none;
  margin: auto;
  padding: 10px 40px;
  max-width: 1440px;
  text-align: left;
`;

const CopyrightRootContainer = styled(Grid)`
  background: ${theme.status.yellow.emnos};
`;

const CopyrightWrapper = styled(Grid)`
  padding: 10px 40px;
  overflow: hidden;
  position: relative;
  display: block;
  text-align: left;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 0.93em;
  margin: auto;
  max-width: 1440px;
  background: ${theme.status.yellow.emnos};
`;

const Column = styled(Grid)`
  display: flex;
  flex-direction: column;
  flex-basis: 33.3%;
  max-width: 33.3%;
  padding-left: 2%;
  padding-right: 2%;
`;

const LogoWrapper = styled(Grid)`
  && {
    border-right: 1px solid;
    border-color: ${theme.status.grey.greyBG};
    padding-bottom: 2rem;
    padding-right: 2rem;
    text-align: right;
    max-width: 35%;
    flex-basis: 35%;
  }
`;

const Section = styled(Typography)`
  && {
    font-family: "Open Sans";
    font-weight: 400;
    padding-left: 0.5rem;
    margin-bottom: 10px;
    line-height: 1.3;
    @media (min-width: 960px) {
      font-size: 1.5rem;
    }
  }
`;

const SectionURL = styled.a`
  text-decoration: none;
  font-family: "Open Sans";
  font-weight: 400;
  padding-left: 0.5rem;
  margin-bottom: 25px;
  line-height: 1.3;
  color: ${theme.status.grey.white};
  @media (min-width: 960px) {
    font-size: 1.5rem;
  }
  
  &:hover {
    text-decoration: none;
    color: ${theme.status.yellow.emnos};
  }
`;


const Copyright = styled(Typography)`
  display: block;
  line-height: 1.8rem;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 0.93em;
  color: ${theme.status.blue.superDark};
  float: left;
  padding-left: 2%;
`;

const Link = styled(Typography)`
  && {
    font-family: "Open Sans";
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    list-style-type: none;
    position: relative;
    @media (min-width: 960px) {
      font-size: 0.93rem;
    }
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  line-height: 1.5em;
  margin-bottom: 10px;
  padding-bottom: 10px;
  list-style-type: none;
  position: relative;
  border-bottom: 1px solid rgb(255,255,255,0.2);
  &:hover * {
    text-decoration: none;
    color: ${theme.status.yellow.emnos};
  }
`;

const ArrowIcon = styled(KeyboardArrowRightIcon)`
  && {
    color: ${theme.status.grey.white};
    font-size: 0.93rem;
  }
`;

const BottomSection = styled(Typography)`
  && {
    float: right;
    padding-right: 2%;
  }
`;

const BottomAnchor = styled.a`
  margin-left: 15px;
  line-height: 1.8rem;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 0.93em;
  color: ${theme.status.blue.superDark};
  text-decoration: none;
  
  &:hover {
    border-bottom: 1px solid;
    color: ${theme.status.grey[100]};
  }
`;

export const sections = [
  {
    title: "",
    items: [
      { text: "Solutions", url: "https://www.emnos.com/solutions" }
    ]
  },
  {
    title: "",
    items: [
      { text: "About us", url: "https://www.emnos.com/about-us" }
    ]
  },
  {
    title: "",
    items: [
      { text: "Contact", url: "https://www.emnos.com/contact" }
    ]
  }
];

export const bottomSections = [
  { text: "Privacy Statement", url: "/portal/data-privacy" },
  { text: "Legal", url: "/portal/legal" }
];

const LinkItem = (i, item, isLoggedIn) => (
  <Anchor
    key={"type" + i}
    href={item.privateUrl && isLoggedIn ? item.privateUrl : item.url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <ArrowIcon />
    <Link variant="caption" color="textSecondary">
      {item.text}
    </Link>
  </Anchor>
);

class Footer extends Component {
  render() {
    const { isLoggedIn, data } = this.props;
    return (
      <RootContainer container spacing={0} justifyContent="flex-start">
        <Container container spacing={0} justifyContent="flex-start">
          {data.sections.map((elem, idx) => (
            <Column item xs={4} sm={2} key={"foot" + idx}>
              <Section variant="body2" color="textSecondary">
                {elem.titleUrl && (
                  <SectionURL href={elem.titleUrl} target="_blank" rel="noopener noreferrer">
                    {elem.title}
                  </SectionURL>
                )}
                {elem.titleUrl ? null : elem.title}
              </Section>
              {elem.items.map((item, i) => LinkItem(i, item, isLoggedIn))}
            </Column>
          ))}
        </Container>
        <CopyrightRootContainer container spacing={0} justifyContent="flex-start">
          <CopyrightWrapper container spacing={0} justifyContent="flex-start">
            <Copyright variant="caption" color="textSecondary">
              &copy; {new Date().getFullYear()} emnos GmbH. {data.trademark}
            </Copyright>
            <BottomSection>
              {data.bottomSections.map((elem, idx) => (
                <BottomAnchor key={"bottomAnchor" + idx} href={elem.url} target="_blank" rel="noopener noreferrer">
                  {elem.text}
                </BottomAnchor>
              ))}
            </BottomSection>
          </CopyrightWrapper>
        </CopyrightRootContainer>
      </RootContainer>
    );
  }
}

Footer.propTypes = {
  isLoggedIn: PropTypes.bool,
  data: PropTypes.shape({
    trademark: PropTypes.string,
    sections: PropTypes.array,
    bottomSections: PropTypes.array
  })
};

Footer.defaultProps = {
  isLoggedIn: false,
  data: {
    trademark: "All rights reserved.",
    sections: sections,
    bottomSections: bottomSections
  }
};

function FooterWithTheme(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Footer {...props} />
    </MuiThemeProvider>
  );
}

export default FooterWithTheme;
