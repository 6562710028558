// helper function to search for a node by paths
export const findNodeByPaths = (nameKey = "name", nodes = [], paths = []) => {
  let stack = paths.slice(0);
  let match = null;
  while (stack.length > 0) {
    const path = stack[0];
    match = nodes.filter(n => n && String(n[nameKey]) === path)[0];
    nodes = match ? match.children : [];
    stack = stack.slice(1);
  }
  return match;
};

export function findNode(nodeName, nameKey, nodes, searchPaths) {
  const searchPath = searchPaths[nodeName].split("|");
  return findNodeByPaths(nameKey, nodes, searchPath);
}

export function findParentNode(nodeName, nameKey, nodes, searchPaths) {
  const searchPath = searchPaths[nodeName].split("|");
  if (searchPath.length < 2) {
    // it has no parent
    return null;
  }
  searchPath.pop();
  return findNodeByPaths(nameKey, nodes, searchPath);
}

// Deselect all node children
export function resetNodeChildren(node) {
  if (!Array.isArray(node.children)) return node;
  let stack = node.children;
  let n;
  while (stack.length > 0) {
    n = stack[0];
    n.selected = false;
    if (n.children) {
      stack = stack.concat(n.children);
    }
    stack = stack.slice(1);
  }
  return node;
}

export function createTreeNode(id, node, existingNode = {}) {
  const treeNode = {
    ...existingNode,
    id,
    childrenIds: []
  };
  for (const prop in node) {
    if (prop !== "id" && prop !== "children") {
      treeNode[prop] = node[prop];
    }
  }
  return treeNode;
}
export function normalizeTree(children, nameKey = "id", result = {}) {
  let stack = children;
  let n;
  let treeId;
  let treeNode;
  while (stack.length > 0) {
    n = stack[0];
    treeId = String(n[nameKey]);
    treeNode = createTreeNode(treeId, n);
    result[treeId] = treeNode;
    if (n.children) {
      result[treeId].childrenIds = n.children.map(el => String(el.id));
      stack = stack.concat(n.children);
    }
    stack = stack.slice(1);
  }
  // create root node
  stack = children;
  treeId = "root";
  result[treeId] = {
    id: treeId,
    childrenIds: stack.map(el => String(el.id))
  };
  return result;
}

export function appendNormalizedTree(parentId, children, normalizedTree) {
  const parentNode = normalizedTree[parentId];
  for (let i = 0; i < children.length; ++i) {
    const childId = String(children[i].id);
    const existingNode = normalizedTree[childId] || {};
    normalizedTree[childId] = createTreeNode(
      childId,
      children[i],
      existingNode
    );
    parentNode.childrenIds.push(childId);
  }
  return normalizedTree;
}

// helper function to build a search tree to create a path that we can traverse to
// find a node quickly. We do this to avoid a brute force seach when looking for the location
// of a cluster.
export function buildSearchPaths(
  children,
  nameKey = "name",
  dict = {},
  path = ""
) {
  if (
    typeof children === "undefined" ||
    children === null ||
    !Array.isArray(children)
  ) {
    return dict;
  }

  return children.reduce((prev, node) => {
    let nodeName = node[nameKey];
    let currPath = `${path}${path.length ? "|" : ""}${nodeName}`;
    prev[nodeName] = currPath;
    return buildSearchPaths(node.children, nameKey, prev, currPath);
  }, dict);
}
