export const REQUEST = "REQUEST";
export const RECEIVE = "RECEIVE";
export const ERROR = "ERROR";
export const QUESTION_INDEX = "QUESTION_INDEX";

export const STATUS = {
  LOADING: "LOADING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR"
};

export const initialUserFeedbackState = {
  status: STATUS.IDLE,
  data: null,
  error: null,
  questionIndex: 0
};

export function userFeedbackReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case QUESTION_INDEX:
      return {
        ...state,
        questionIndex: payload
      };
    case REQUEST:
      return {
        ...state,
        status: STATUS.LOADING
      };
    case RECEIVE:
      return {
        ...state,
        status: STATUS.COMPLETE,
        data: payload
      };
    case ERROR:
      return {
        ...state,
        status: STATUS.ERROR,
        error: payload
      };
    default:
      throw new Error();
  }
}
