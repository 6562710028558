import React from "react";
import PropTypes from "prop-types";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";

const StyledSlider = withStyles(theme => ({
  root: {
    color: theme.status.midnight.dark,
    height: 3,
    padding: "20px 0"
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: theme.status.midnight.dark,
    marginTop: -13,
    marginLeft: -16
  },
  active: {},
  valueLabel: {
    left: -2,
    top: 8,
    "& *": {
      background: "transparent",
      color: theme.status.grey.white
    }
  },
  track: {
    height: 3
  },
  rail: {
    height: 3,
    opacity: 0.5,
    backgroundColor: theme.status.midnight[50]
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor"
  }
}))(Slider);

const TimePeriodDurationSlider = ({
  maxValue,
  minValue,
  value,
  handleChange,
  disabled
}) => {
  return (
    <StyledSlider
      min={minValue}
      max={maxValue}
      value={value}
      disabled={disabled}
      onChange={(e, newValue) => {
        handleChange(newValue);
      }}
      valueLabelDisplay="on"
    />
  );
};

TimePeriodDurationSlider.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default TimePeriodDurationSlider;
