// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Cw8bydz0SqOs58PsA1jup {\n  position: absolute;\n  left: -18px;\n  top: 0;\n  display: block;\n  width: 24px;\n  height: 24px;\n  margin-right: 4px;\n  text-align: center;\n  outline: none;\n  background-color: transparent;\n  border: none;\n  border-radius: 2px;\n  color: #5a6174;\n  cursor: pointer;\n}\n\n.Cw8bydz0SqOs58PsA1jup:hover {\n  color: #313a51;\n  background-color: #ffffff;\n}\n\n._3XsEeP5sQF4AeltYhHLTzH {\n  color: #313a51;\n}\n", ""]);
// Exports
exports.locals = {
	"toggle-button": "Cw8bydz0SqOs58PsA1jup",
	"toggleButton": "Cw8bydz0SqOs58PsA1jup",
	"toggle-button-expanded": "_3XsEeP5sQF4AeltYhHLTzH",
	"toggleButtonExpanded": "_3XsEeP5sQF4AeltYhHLTzH"
};
module.exports = exports;
