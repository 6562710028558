// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3G2D7fvlY8zCRH_tfOISv6 {\n  position: relative;\n  margin: 0;\n  padding: 0 0 0 1rem;\n  font-size: 14px;\n  font-family: Arial, Helvetica, sans-serif;\n  list-style: none;\n}\n", ""]);
// Exports
exports.locals = {
	"list": "_3G2D7fvlY8zCRH_tfOISv6"
};
module.exports = exports;
