// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@material-ui/core/Button";

const CustomButton = ({
  className,
  actionHandlers,
  action,
  url,
  id,
  children
}) => {
  const handler = actionHandlers && actionHandlers[action];
  const hasHandler = Boolean(handler);
  return (
    <Button
      className={className}
      href={hasHandler ? undefined : url}
      id={id}
      onClick={hasHandler ? handler : null}
    >
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  actionHandlers: PropTypes.object,
  action: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string
};

CustomButton.defaultProps = {
  url: "/",
  id: "btn-primary"
};

export default CustomButton;
