export const blue = {
  10: "#f9fcfd",
  20: "#f3f9fc",
  40: "#c4e2f0",
  100: "#6CB6D9",
  dark: "#0066B3",
  extraDark: "#024272",
  superDark: "#313A51",
  emnos: "#D7DEEB"
};

export const cyan = {
  10: "#f4fcfe",
  20: "#ebfafe",
  40: "#99e4f9",
  80: "#33c9f3",
  100: "#00bcf0",
  dark: "#0291b9"
};

export const green = {
  10: "#f2f8f2",
  20: "#e5f2e7",
  40: "#cce6ce",
  80: "#99cd9d",
  100: "#7FC085",
  dark: "#4D8A53"
};

export const grey = {
  white: "#ffffff",
  greyBG: "#fcfcfc",
  greyBox: "#f8f8f8",
  greyDivs: "#d8d8d8",
  100: "#929292",
  dark: "#404040"
};

export const mauve = {
  10: "#f6f4f8",
  20: "#eeeaf2",
  40: "#dcd6e5",
  80: "#b9adca",
  100: "#a898bd",
  dark: "#796395"
};

export const orange = {
  10: "#fdf2ee",
  20: "#fde6df",
  40: "#fbccbe",
  80: "#f6997d",
  100: "#f4805d",
  dark: "#bb5739"
};

export const pink = {
  10: "#faeef3",
  20: "#f5dde7",
  40: "#ecbcd0",
  80: "#d979a1",
  100: "#cf5789",
  dark: "#91375d"
};

export const red = {
  10: "#fdeff0",
  20: "#fcdfe2",
  40: "#f9c0c5",
  80: "#f4818c",
  100: "#f1616f",
  dark: "#bc3441"
};

export const clay = {
  10: "#F8F7F6",
  20: "#f2f0ed",
  30: "#ece8e4",
  40: "#e6e1dc",
  50: "#dfd9d3",
  60: "#d9d1ca",
  70: "#d2c9c1",
  80: "#cdc2b9",
  90: "#c6baaf",
  100: "#c0b3a7"
};

export const teal = {
  10: "#eef9f9",
  20: "#def3f3",
  40: "#bce8e7",
  80: "#79d1d0",
  100: "#58c5c4",
  dark: "#34908f"
};

export const yellow = {
  10: "#fefaf3",
  20: "#fff6e7",
  40: "#ffeccf",
  80: "#fed9a0",
  100: "#fed088",
  dark: "#d0a460",
  emnos: "#F8E71C"
};
