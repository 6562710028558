import React from "react";
import PropTypes from "prop-types";

import { DatePicker } from "@material-ui/pickers";

const TimePeriodDateDisplay = ({ label, dateValue, minDate, maxDate }) => (
  <div style={{ marginBottom: 10 }}>
    <DatePicker
      style={{ width: "100%" }}
      label={label}
      format="L"
      value={dateValue}
      maxDate={maxDate}
      minDate={minDate}
      disabled
      readOnly
    />
  </div>
);

TimePeriodDateDisplay.propTypes = {
  label: PropTypes.string,
  minDate: PropTypes.object.isRequired,
  maxDate: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  dateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

TimePeriodDateDisplay.defaultProps = {
  label: "Date Display label"
};

export default TimePeriodDateDisplay;
