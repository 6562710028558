import React from "react";
import PropTypes from "prop-types";

import crfBe from "../../assets/crf_be_retailer.jpg";
import crfFr from "../../assets/crf_fr_retailer.jpg";
import gelUs from "../../assets/gel_us_retailer.jpg";
import kvaUs from "../../assets/kva_us_retailer.jpg";
import lenRu from "../../assets/len_ru_retailer.jpg";
import pdoPt from "../../assets/pdo_pt_retailer.jpg";
import biePl from "../../assets/bie_pl_retailer.jpg";
import magRu from "../../assets/mag_ru_retailer.jpg";
import gigAt from "../../assets/gig_at_retailer.jpg";

const assets = {
  crf_be: crfBe,
  crf_fr: crfFr,
  gel_us: gelUs,
  kva_us: kvaUs,
  len_ru: lenRu,
  pdo_pt: pdoPt,
  bie_pl: biePl,
  mag_ru: magRu,
  gig_at: gigAt
};

const RetailerLogo = ({ retailerName }) =>
  assets[retailerName] ? (
    <img
      style={{ marginLeft: "0.9rem" }}
      src={assets[retailerName]}
      alt={retailerName}
    />
  ) : null;

RetailerLogo.propTypes = {
  retailerName: PropTypes.string
};

export default RetailerLogo;
