// React
import React, { useContext } from "react";
import PropTypes from "prop-types";

// Material UI
import Popover from "@material-ui/core/Popover";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import { withStyles } from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// components
import AccountHeader from "./AccountHeader/AccountHeader";
import CustomButton from "../CustomButton/CustomButton";

// Context
import TranslationContext from "../../context/TranslationContext";

// Styles
const styles = theme => ({
  actions: {
    padding: 20,
    alignItems: "flex-start",
    height: 80,
    backgroundColor: theme.status.grey.greyBox,
    borderTop: `1px solid ${theme.status.grey.greyDivs}`
  },
  logout: {
    color: theme.status.blue.superDark,
    fontSize: "0.875rem",
    lineHeight: 1,
    padding: "6px 25px",
    border: `1px solid ${theme.status.blue.superDark}`,
    borderRadius: "3.125rem",
    margin: "0 auto",
    minHeight: "inherit",
    "&:hover": {
      backgroundColor: theme.status.blue.emnos
    }
  }
});

const Account = ({
  userName,
  isOpen,
  triggerElement,
  handleClose,
  actionHandlers,
  classes
}) => {
  const translations = useContext(TranslationContext);
  return (
    <Popover
      open={isOpen}
      anchorEl={triggerElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      <Card>
        <AccountHeader userName={userName} actionHandlers={actionHandlers} />
        <CardActions disableSpacing className={classes.actions} id="action-logout">
          <CustomButton
            className={classes.logout}
            actionHandlers={actionHandlers}
            action="onUserLogout"
            url="/logout"
            id="pl-logout"
          >
            <ExitToAppIcon style={{ marginRight: "5px" }} />
            {translations.log_out}
          </CustomButton>
        </CardActions>
      </Card>
    </Popover>
  );
};

Account.propTypes = {
  isOpen: PropTypes.bool,
  triggerElement: PropTypes.object,
  handleClose: PropTypes.func,
  userName: PropTypes.string,
  actionHandlers: PropTypes.object
};

Account.defaultProps = {
  isOpen: false,
  triggerElement: {}
};

export default withStyles(styles)(Account);
