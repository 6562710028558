import React, { useState, useReducer, useCallback, useMemo } from "react";
import Fab from "@material-ui/core/Fab";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Zoom from "@material-ui/core/Zoom";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import {
  initialUserFeedbackState,
  userFeedbackReducer,
  QUESTION_INDEX,
  STATUS
} from "./userFeedbackReducer";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1,
    display: "flex",
    position: "fixed",
    right: 20,
    bottom: 20,
    justifyContent: "flex-end",
    alignItems: "baseline"
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

export const UserFeedbackContainer = ({ data, status, onSubmit }) => {
  const theme = useTheme();

  const [userFeedbackState, userFeedbackDispatch] = useReducer(
    userFeedbackReducer,
    initialUserFeedbackState
  );

  const { questionIndex } = userFeedbackState;

  const question = useMemo(() => {
    const { questionIndex } = userFeedbackState;
    const hasQuestion = Array.isArray(data) && questionIndex < data.length;
    return hasQuestion ? data[questionIndex] : null;
  }, [data, userFeedbackState.questionIndex]);

  const [isOpen, setOpen] = useState(false);

  const [answers, setAnswers] = React.useState({});

  const handleAnswerToggle = (value, questionId, questionType) => () => {
    const hasExistingAnswer = Object.prototype.hasOwnProperty.call(
      answers,
      questionId
    );

    let answerList = hasExistingAnswer ? answers[questionId] : [];
    let answerIndex = answerList.indexOf(value);

    if (questionType === "multi-select") {
      if (answerIndex === -1) {
        answerList.push(value);
      } else {
        answerList.splice(answerIndex, 1);
      }
    } else {
      if (answerIndex === -1) {
        answerList = [value];
      } else {
        answerList.splice(answerIndex, 1);
      }
    }

    const newAnswers = {
      ...answers,
      [questionId]: answerList
    };

    setAnswers(newAnswers);
  };

  const validAnswer = useMemo(() => {
    if (!data || !data[questionIndex]) return false;
    const questionId = data[questionIndex].id;
    return Array.isArray(answers[questionId]) && answers[questionId].length > 0;
  }, [questionIndex, data, answers]);

  const handleNextClick = useCallback(
    event => {
      const { questionIndex } = userFeedbackState;
      if (questionIndex < data.length - 1) {
        // go next.
        userFeedbackDispatch({
          type: QUESTION_INDEX,
          payload: questionIndex + 1
        });
      } else {
        // submit!
        onSubmit(answers);
        setOpen(false);
      }
    },
    [userFeedbackState.questionIndex, data, answers, onSubmit]
  );

  const classes = useStyles();
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = event => {
    setOpen(false);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(isOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  return (
    <div className={classes.root}>
      <div>
        <Zoom
          in={Array.isArray(data) && status !== "COMPLETE"}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              Array.isArray(data) && status !== "COMPLETE"
                ? transitionDuration.exit
                : 0
            }ms`
          }}
          unmountOnExit
        >
          <Fab
            color="primary"
            aria-controls={isOpen ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            ref={anchorRef}
            onClick={handleToggle}
          >
            <QuestionAnswerIcon htmlColor="#ffffff" />
          </Fab>
        </Zoom>
        <Popper
          open={isOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="top"
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <List
                    id="list-questions"
                    style={{ minWidth: 250, marginBottom: 16 }}
                    onKeyDown={handleListKeyDown}
                  >
                    {question && question.Question && (
                      <ListItem divider dense>
                        <ListItemText primary={question.Question} />
                      </ListItem>
                    )}
                    {Array.isArray(question.Options) &&
                      question.Options.map(el => (
                        <ListItem
                          key={el.id}
                          role={undefined}
                          dense
                          button
                          disableRipple
                          onClick={handleAnswerToggle(
                            el.id,
                            question.id,
                            question.type
                          )}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              color="primary"
                              checked={
                                Array.isArray(answers[question.id]) &&
                                answers[question.id].indexOf(el.id) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                "aria-labelledby": `checkbox-list-label-${el.id}`
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={`checkbox-list-label-${el.id}`}
                            primary={el.option}
                          />
                        </ListItem>
                      ))}
                    <ListItem key="button">
                      {question && question.Question && data.length > 1 && (
                        <Typography variant="caption">{`${questionIndex + 1}/${
                          data.length
                        }`}</Typography>
                      )}
                      <Button
                        style={{ marginLeft: "auto" }}
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="small"
                        disabled={!validAnswer || status === STATUS.LOADING}
                        onClick={handleNextClick}
                      >
                        {status !== STATUS.LOADING && <span>Next</span>}
                        {status === STATUS.LOADING && <span>Loading</span>}
                      </Button>
                    </ListItem>
                  </List>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
