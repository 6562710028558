import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./FileFolderLabel.module.css";

const FileFolderLabel = React.forwardRef(function FileFolderLabel(
  { handleClick, selected, children },
  ref
) {
  return (
    <span
      ref={ref}
      className={classNames(
        {
          [styles.labelSelected]: selected
        },
        styles.label
      )}
      onClick={handleClick}
    >
      {children}
    </span>
  );
});

FileFolderLabel.propTypes = {
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default FileFolderLabel;
